import jump from "jump.js"

export const initForms = () => {
	const forms = Array.from(document.querySelectorAll(`form`));
	// const forms = Array.from(document.querySelectorAll(`form[action="https://docs.google.com/forms/u/1/d/e/1FAIpQLSdl9V6NXjrE6BgErmEDc-aeR578FLpZcp5oWEas7YVwbyvprA/formResponse"]`));

	if ( forms.length )
	{
		const promoButton = document.querySelector(`.promo__subscribe-button`);

		if ( promoButton )
		{
			const buttonWrapper = document.querySelector(`.promo__subscribe-wrapper`);
			const promoInput = document.querySelector(`.promo__subscribe-input`);

			window.addEventListener(`scroll`, () => {
				if ( window.innerWidth < 1024 )
				{
					const promoInputPosition = promoInput.getBoundingClientRect();
					const promoButtonPosition = promoButton.getBoundingClientRect();

					 if ( promoInputPosition.top + promoInputPosition.height + 16 - 20 <= 0 )
					 {
						 promoButton.classList.add(`promo__subscribe-button_fixed`);
						 buttonWrapper.style.paddingBottom = `${16 + promoButtonPosition.height}px`;
					 } else
					 {
						 promoButton.classList.remove(`promo__subscribe-button_fixed`);
						 buttonWrapper.style.paddingBottom = ``;
					 }
				}
			});

			promoButton.addEventListener(`click`, (evt) => {
				if ( window.innerWidth < 1024 )
				{
					if ( promoButton && promoButton.classList.contains(`promo__subscribe-button_fixed`))
					{
						evt.preventDefault();

						jump(buttonWrapper, {
							duration: 700,
							offset: -164,
						})
					}
				}
			});
		}

		forms.forEach( (form) => form.addEventListener(`submit`, (evt) => {
			evt.preventDefault();

			const xhr = new XMLHttpRequest();
			const email = form.querySelector(`input`);
			const button = form.querySelector(`button`);
			const formData = new FormData();
			formData.append(email.name, email.value);

			xhr.open('POST', form.getAttribute(`action`), true);
			xhr.send(formData);

			email.disabled = true;
			button.disabled = true;

			xhr.onreadystatechange = function() {
				// if (xhr.readyState !== 4 || xhr.status !== 200)
				// {
				// 	form.classList.add(`${form.classList[0]}_error`);
				// 	setTimeout(() => {
				// 		form.classList.remove(`${form.classList[0]}_error`);
				// 		email.disabled = false;
				// 		button.disabled = false;
				// 	}, 5000);
				// 	return;
				// }
				if (xhr.readyState !== 4 ) return;
				form.classList.add(`${form.classList[0]}_success`);
				document.querySelector('.promo__subscribe-counter').textContent = parseInt( document.querySelector('.promo__subscribe-counter').textContent ) + 1;

				setTimeout(() => {
					form.classList.remove(`${form.classList[0]}_success`);
					email.disabled = false;
					button.disabled = false;
					form.querySelector(`[class*="subscribe-input"]`).value = ``;
				}, 10000);
			}
		}));
	}
};